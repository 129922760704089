<template>
  <div class="order">
    <div class="page-header">
      <h1>{{ $t('Order Management') }} > {{ $t('Orders') }} > {{ $t('Add') }}</h1>
    </div>
    <div class="page-content">
      <div class="content-header">
        <h2>{{ $t('Add Order') }}</h2>
      </div>
      <CreateOrderForm @action="addOrder"/>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Common from '@/lib/common';
import Order from '@/lib/order';
import CreateOrderForm from '@/components/order/CreateOrderForm.vue';

export default {
  name: 'CreateOrder',
  components: {
    CreateOrderForm
  },
  methods:{
    async addOrder(form){
      try{
        const loginInfo = Common.getLoginInfo();
        const order = await Order.createOrder(this.apiUrl, form, loginInfo);
        this.$router.push('/orders');
      }catch(err){
        console.log(err);
      }
    },
  },
  computed: mapState({
   langcode: state => state.langcode,
   apiUrl: state => state.apiUrl,
 }),
}
</script>

<style scoped lang="scss">
.content-header{
  display: flex;
  h2{
    flex: 1;
  }
  .action-button{
    margin-right: 20px;
  }
}
</style>
