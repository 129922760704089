<template>
  <div v-if="product" :class="['product', {'selected-product': selected }]" @click="selectProduct">
    <div class="product-image-wrapper">
      <img class="product-image img-fluid" v-if="getProductImage(product.product_images)" :src="getProductImage(product.product_images)" />
      <img class="product-image img-fluid" v-else src="@/assets/no-image.jpg" />
    </div>
    <div class="product-name" v-if="langcode === 'zh'">
      {{ product.product_name_zh }}
    </div>
    <div class="product-name" v-else>
      {{ product.product_name_en }}
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Common from '@/lib/common';
import Product from '@/lib/product';

export default {
  name: 'ProductItem',
  props: {
    product: Object,
  },
  data(){
    return {
      selected: false,
    };
  },
  methods:{
    selectProduct(){
      if(this.selected === false){
        this.$emit('select', this.product);
        this.selected = true;
      }else{
        this.$emit('deselect', this.product);
        this.selected = false;
      }
    },
    getProductImage(imageField){
      if(imageField){
        const images = JSON.parse(imageField);
        const image = images[0];
        return image;
      }
    },
  },
  computed: mapState({
    apiUrl: state => state.apiUrl,
    langcode: state => state.langcode,
  }),
}
</script>
