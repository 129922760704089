<template>
  <div class="select-product">
    <div class="row">
      <el-input style="max-width: 60%; padding-left: 20px; margin-right: 30px;" v-model="productName" :placeholder="$t('Enter the name of product')"></el-input>
      <el-button @click="searchProduct">{{ $t('Search') }}</el-button>
    </div>
    <div class="product-list" v-loading="loading">
      <ProductItem v-for="(product, index) in products" :key="product.id" :product="product"
      @select="selectProduct" @deselect="deselectProduct"/>
    </div>
    <div class="dialog-footer">
      <el-button type="primary" @click="saveProduct">{{ $t('Save') }}</el-button>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import dayjs from 'dayjs';
import ProductItem from '@/components/order/ProductItem';
import Common from '@/lib/common';
import Product from '@/lib/product';

export default {
  name: 'SelectProduct',
  components: {
    ProductItem,
  },
  data(){
    return {
      products: [],
      productName: '',
      activeProducts: [],
      loading: true,
    };
  },
  mounted(){
    this.loadAllProducts();
  },
  methods:{
    selectProduct(product){
      this.activeProducts.push(product);
    },
    deselectProduct(product){
      const productIndex = this.activeProducts.findIndex(function(item){
        return product.id === item.id;
      });
      if(productIndex !== -1){
        this.activeProducts.splice(productIndex, 1);
      }
    },
    getProductImage(imageField){
      if(imageField){
        const images = JSON.parse(imageField);
        const image = images[0];
        return image;
      }
    },
    saveProduct(){
      const selectedProducts = this.activeProducts;
      this.$emit('productSelected', selectedProducts);
    },
    async loadAllProducts(){
      const loginInfo = Common.getLoginInfo();
      const products = await Product.loadAllProducts(this.apiUrl, loginInfo);
      this.loading = false;
      this.products = products;
    },
    async searchProduct(){
      const loginInfo = Common.getLoginInfo();
      const searchFields = {
        //'product_name': this.productName,
      };
      const products = await Product.searchProduct(this.apiUrl, searchFields, loginInfo);
      this.products = products;
    },
  },
  computed: mapState({
    apiUrl: state => state.apiUrl,
    langcode: state => state.langcode,
  }),
}
</script>

<style lang="scss">
.select-product{
  .table{
    margin-top: 30px;
  }
  .dialog-footer{
    text-align: right;
    margin-top: 30px;
  }
  .el-radio__label{
    display: none;
  }

  .product-list{
    display: flex;
    margin-top: 50px;
    flex-wrap: wrap;
    .product{
      margin-right: 30px;
      margin-bottom: 50px;
      &:last-child{
        margin-right: 0;
      }
    }
  }

  .selected-product{
    border: 3px solid #66B1FF;
  }

  .product-image-wrapper{
    width: 110px;
    height: 110px;
    position: relative;
    overflow: hidden;
    .product-image{
      overflow: hidden;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
</style>
